import './App.css';
import LoginForm from './views/loginForm';
import InsertEmail from './views/InsertEmail';
import ChangeInitialPassword from './views/ChangeInitialPassword';
import ChangePasswordbyEmail from './views/ChangePasswordbyEmail';
import ChangePassword from './views/ChangePassword';
import InsertCode from './views/InsertCode';
import { Route, Routes, HashRouter } from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<LoginForm/>} />
        <Route exact path="/InserEmail" element={<InsertEmail/>} />
        <Route exact path="/ChangeInitialPassword" element={<ChangeInitialPassword/>} />
        <Route exact path="/ChangePasswordbyEmail" element={<ChangePasswordbyEmail/>} />
        <Route exact path="/InsertCode" element={<InsertCode/>} />
        <Route exact path="/ChangePassword" element={<ChangePassword/>} />
      </Routes>
    </HashRouter>
    
  );
}

export default App;
