import React, { useState } from 'react';
import Lottie from "lottie-react";
import background_1 from "../animations/background_1.json";
import Style from '../styles/ChangeInitialPass.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { decrypt, encrypt } from "../helpers/Helpers";
import CheckCode from '../services/CheckCode'
import { useNavigate } from 'react-router-dom';


function InsertCode() {

    const [code, setCode] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleClick = () => {
        setLoading(true)
        setError(false)
        CheckCode({crewCode:sessionStorage.getItem('crewCode'), code:code}).then(res => {
            if(res == 200){
                navigate('/ChangePassword', { replace: true })
            }else{
                setLoading(false)
                setError(true)
            }
        })
    }


    return (
        <div>
             {error ? (
                <div style={{fontSize:'large', paddingTop:'5px', paddingBottom:'5px', position:"absolute", zIndex:'9999999999', backgroundColor:'red', width:'100%', textAlign:'center', color:'white'}}>Error: try again later</div>
            ):(
                <></>
            )
            }
            <div className={Style.ChangePassContainer}>
                <div className={Style.form_container}>
                    <h3 className={Style.form_title}>INSERT CODE</h3>
                    <div className={Style.password_container}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className={Style.textfield}
                            type= "text"
                            value={code}
                            size="small"
                            placeholder="CODE"
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                   
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        Submit
                    </Button>
                </div>
            </div>

            <Lottie
                className={Style.lottie_file}
                autoplay={false}
                loop
                animationData={background_1}
            />
        </div>
    );
}

export default InsertCode;
