import {
    React
} from 'react';
import "../../styles/loginFormStyle.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

function ControlUser(props) {

    const { open, onClose, data } = props;

    const navigate = useNavigate();

    const ControlInfo = () => {

        if (data != undefined && data.length != 0) {
            if (data[0].Confirmado == "RE" || data[0].Confirmado == "No") {
                switch (data[0].Confirmado) {
                    case "RE": return <p>Uninitialized user, please contact your manager to obtain the initial password</p>
                    case "No": return <p>User blocked in the system, contact your manager to modify their status</p>
                }
            } 
        } else {
            return <p>The user doesn`t exist. If you need a user, contact your manager</p>
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="header_dialog">
                    {"User"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="content_dialog">
                        {ControlInfo()}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ControlUser; 