import { useState } from "react";
import Lottie from "lottie-react";
import background_1 from "../animations/background_1.json";
import Style from '../styles/ChangeInitialPass.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EnviarEmail from "../services/EnviarEmail";
import { decrypt, encrypt } from "../helpers/Helpers";
import { useNavigate } from 'react-router-dom';
import moment from "moment";


function ChangePasswordByEmail() {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Expresión regular para validar el formato del email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = () => {
        setLoading(true);
        if (validateEmail(email)) {
            setEmailError('');
            EnviarEmail({crewCode:sessionStorage.getItem('crewCode'), email:email, timestamp:moment().format('YYYY-MM-DD HH:mm:ss')}).then(res => {
                if(res == 200){
                    navigate('/InsertCode', { replace: true })
                }else{
                    setError(true)
                    setTimeout(function() {
                        sessionStorage.clear()
                        navigate('/', { replace: true })
                    }, 5000);
                    
                }
            })
            // Aquí puedes realizar la acción de enviar el email, como llamar a una API
           
        } else {
            setEmailError('El formato del correo electrónico es inválido.');
        }
    };

    return (
        <div>
            {error ? (
                <div style={{fontSize:'large', paddingTop:'5px', paddingBottom:'5px', position:"absolute", zIndex:'9999999999', backgroundColor:'red', width:'100%', textAlign:'center', color:'white'}}>Error: try again later</div>
            ):(
                <></>
            )
            }
            <div className={Style.ChangePassContainer}>
                <div className={Style.form_container}>
                    <h3 className={Style.form_title}>INSERT EMAIL</h3>
                    <div className={Style.password_container}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className={Style.textfield}
                            type='text'
                            value={email}
                            size="small"
                            placeholder="EMAIL"
                            onChange={(e) => setEmail(e.target.value)}
                            error={Boolean(emailError)}
                            helperText={emailError}
                        />
                    </div>
                    
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>

            <Lottie
                className={Style.lottie_file}
                autoplay={false}
                loop
                animationData={background_1}
            />
        </div>
    );
}

export default ChangePasswordByEmail;
