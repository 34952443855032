import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import background_1 from "../animations/background_1.json";
import Style from '../styles/ChangeInitialPass.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BiShow, BiHide } from "react-icons/bi";
import InputAdornment from '@mui/material/InputAdornment';
import ModifyInitialPassword from "../services/ModifyInitialPasssword";
import { decrypt, encrypt } from "../helpers/Helpers";
import { useNavigate } from 'react-router-dom';

function ChangeInitialPassword() {

    const [pass, setPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [currentPass, setCurrentPass] = useState('');
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({ pass: '', repeatPass: '' });
    const [showPass, setShowPass] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const storedCurrentPass = decrypt(sessionStorage.getItem('current'), process.env.REACT_APP_KEY);
        if (storedCurrentPass) {
            setCurrentPass(storedCurrentPass);
        }
    }, []);

    useEffect(() => {
        validatePass();
    }, [pass, repeatPass]);

    const validatePass = () => {
        let passErrors = '';
        let repeatPassErrors = '';

        if (pass != '' && repeatPass != '') {
            if (pass !== repeatPass) {
                repeatPassErrors = 'Passwords do not match.';
            }
            if (pass.length < 10) {
                passErrors = 'Must be min 10 characters';
            }
            if (!/[A-Za-z]/.test(pass)) {
                passErrors = 'Must contain min one letter';
            }
            if (!/[0-9]/.test(pass)) {
                passErrors = 'Must contain min one number';
            }
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(pass)) {
                passErrors = 'Must contain min one special char';
            }
            if (currentPass === pass) {
                passErrors = 'Can´t be same as current password';
            }
        }
        setErrors({ pass: passErrors, repeatPass: repeatPassErrors });
    };

    const toggleShowPass = () => {
        setShowPass(!showPass);
    };

    const toggleShowRepeatPass = () => {
        setShowRepeatPass(!showRepeatPass);
    };

    const handleClick = () => {
        validatePass();
        if (!errors.pass && !errors.repeatPass) {
            ModifyInitialPassword({
                crewCode: sessionStorage.getItem('crewCode'),
                password: pass
            }).then(res => {
                if (res == 200) {
                    sessionStorage.clear()
                    navigate('/', { replace: true })
                } else {
                    setError(true)
                    setTimeout(function () {
                        sessionStorage.clear()
                        navigate('/', { replace: true })
                    }, 5000);
                }
            });
        } else {
            console.log('Error:', errors);
        }
    }

    //vOserONRdVGyBfI7Cb9PxR/jE3sNrRVQWFGhZRYh5j4=

    return (
        <div>
            {error ? (
                <div style={{ fontSize: 'large', paddingTop: '5px', paddingBottom: '5px', position: "absolute", zIndex: '9999999999', backgroundColor: 'red', width: '100%', textAlign: 'center', color: 'white' }}>Error: try again later</div>
            ) : (
                <></>
            )
            }
            <div className={Style.ChangePassContainer}>
                <div className={Style.form_container}>
                    <h3 className={Style.form_title}>INSERT NEW PASSWORD</h3>
                    <div className={Style.password_container}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className={Style.textfield}
                            type={showPass ? "text" : "password"}
                            value={pass}
                            size="small"
                            placeholder="PASSWORD"
                            onChange={(e) => setPass(e.target.value)}
                            error={errors.pass != '' && Boolean(errors.pass)}
                            helperText={errors.pass}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <button onClick={toggleShowPass} className={Style.show_pass}>{showPass ? <BiHide className={Style.lock_icon} /> : <BiShow className={Style.lock_icon} />}</button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={Style.password_container}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className={Style.textfield}
                            type={showRepeatPass ? "text" : "password"}
                            value={repeatPass}
                            size="small"
                            onChange={(e) => setRepeatPass(e.target.value)}
                            error={repeatPass != '' && Boolean(errors.repeatPass)}
                            helperText={errors.repeatPass}
                            placeholder="REPEAT PASSWORD"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <button onClick={toggleShowRepeatPass} className={Style.show_pass}>{showRepeatPass ? <BiHide className={Style.lock_icon} /> : <BiShow className={Style.lock_icon} />}</button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disabled={Boolean(errors.pass) || Boolean(errors.repeatPass)}
                    >
                        Submit
                    </Button>
                </div>
            </div>

            <Lottie
                className={Style.lottie_file}
                autoplay={false}
                loop
                animationData={background_1}
            />
        </div>
    );
}

export default ChangeInitialPassword;
