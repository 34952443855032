import format from 'date-fns/format';
import CryptoJS from "crypto-js";

export const SaveSession = (token, response) =>{
    sessionStorage.setItem('token', response.data);
    sessionStorage.setItem("username", token.data.username);
    sessionStorage.setItem("Base", token.data.Base);
    sessionStorage.setItem("Fecha", format(new Date(), 'yyyy-MM-dd'));
    sessionStorage.setItem("TipoUser", token.data.TipoUser);
    sessionStorage.setItem("Grupo", token.data.Grupo);
} 

export const SaveLocal = (token, response) =>{
    localStorage.setItem('token', response.data);
    localStorage.setItem("username", token.data.username);
    localStorage.setItem("Base", token.data.Base);
    localStorage.setItem("Fecha", format(new Date(), 'yyyy-MM-dd'));
    localStorage.setItem("TipoUser", token.data.TipoUser);
    localStorage.setItem("Grupo", token.data.Grupo);
} 

export const RedirectionByScreen = (size) => {
    if (size.innerWidth <= 1024) {
        return window.location.replace("../../Home/Tablets/app/");
    } else{
        return window.location.replace("../../Home/Azul/app/");
    }
}

export function ClassNameContainer(size) {
    if (size.innerWidth >= 800) {
        return "container_right";
    } else {
        return "container_right_mobile";
    }
}

export function ClassNameContainerLeft(size) {
    if (size.innerWidth >= 800) {
        return "container_left";
    } else {
        return "container_left_mobile";
    }
}

export function ClassNameForm(size) {
    if (size.innerWidth >= 800) {
        return "container_form";
    } else {
        return "container_form_mobile";
    }
}

export function ClassNameWidthInput(size) {
    if (size.innerWidth >= 800) {
        return "350px";
    } else {
        return "250px";
    }
}

// Función para cifrar datos
export function decrypt(encryptedText, key) {
    // Decodificar el texto en base64
    const data = CryptoJS.enc.Base64.parse(encryptedText);
    
    // Convertir el texto en WordArray
    const dataWords = CryptoJS.lib.WordArray.create(data.words);

    // Extraer el IV (primeros 16 bytes) y el texto encriptado (resto)
    const iv = CryptoJS.lib.WordArray.create(dataWords.words.slice(0, 4));
    const encrypted = CryptoJS.lib.WordArray.create(dataWords.words.slice(4));
    
    // Convertir la clave en WordArray
    const keyWordArray = CryptoJS.enc.Utf8.parse(key);

    // Desencriptar usando AES-256-CBC
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encrypted },
        keyWordArray,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );

    // Convertir el texto desencriptado a una cadena UTF-8
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encrypt(plainText, key) {
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(key), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const encryptedText = iv.concat(encrypted.ciphertext);
    return CryptoJS.enc.Base64.stringify(encryptedText);
}